import * as React from 'react'
import Link from 'gatsby-link'

type evHandler = (ev: React.MouseEvent) => void
type linkTarget = string | 'BACK' | evHandler

type PxlRaisedButtonProps = {
  to: linkTarget
  width: number
  children: string | JSX.Element
}

const defaultLinkProps = (props: PxlRaisedButtonProps) => ({
  className: 'raised',
  style: { minWidth: props.width }
})

interface LinkWrapperProps extends PxlRaisedButtonProps {
  children: JSX.Element
}

const LinkWrapper: React.SFC<LinkWrapperProps> = props => {
  if (props.to === 'BACK') {
    return (
      <a onClick={() => history.back()} role="link" {...defaultLinkProps(props)}>
        {props.children}
      </a>
    )
  }
  if (typeof props.to === 'function') {
    return (
      <a onClick={ev => (props.to as evHandler)(ev)} role="link" {...defaultLinkProps(props)}>
        {props.children}
      </a>
    )
  }
  return (
    <Link to={props.to} {...defaultLinkProps(props)}>
      {props.children}
    </Link>
  )
}

export const PxlRaisedButton: React.SFC<PxlRaisedButtonProps> = props => {
  const uid = `pxlrb-mask--${props.width}-${props.to}-${props.children}`
    .replace(/[ \W]/g, '')
    .toLowerCase()
  return (
    <span className="action">
      <LinkWrapper {...props}>
        <svg viewBox={`0 0 ${props.width} 50`}>
          <defs>
            <mask id={uid} x="0" y="0" width={props.width} height="50">
              <rect x="0" y="0" width={props.width} height="50" fill="#ffffffaa" />
              <text textAnchor="middle" x={props.width / 2} y="33" dy="1" fontSize={18}>
                {props.children}
              </text>
            </mask>
          </defs>
          <rect x="0" y="0" width={props.width} height="50" mask={`url(#${uid})`} fillOpacity="1" />
        </svg>
      </LinkWrapper>
    </span>
  )
}
