import * as React from 'react'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import { PxlRaisedButton } from '../components/Button'
import { Language } from '../state'

const ContactSuccessPage: React.StatelessComponent = () => (
  <IndexLayout>
    <Language.Consumer>
      {language => (
        <Page>
          <div
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: 24,
              left: 0,
              top: 0,
              zIndex: 1,
              fontWeight: 500
            }}
          >
            <h2 style={{ color: '#444', fontWeight: 600, fontSize: '2.4em' }}>
              {language.translate('CONTACT_FEEDBACK_SUCCESS_TITLE')}
            </h2>
            <p style={{ color: '#444', textAlign: 'center', fontSize: '2em', lineHeight: '1.7em' }}>
              {language.translate('CONTACT_FEEDBACK_SUCCESS_MESSAGE')}
            </p>
            <div style={{ marginTop: 16 }}>
              <PxlRaisedButton width={330} to={'/'}>
                {language.translate('ACTION_BACK_TO_START')}
              </PxlRaisedButton>
            </div>
          </div>
        </Page>
      )}
    </Language.Consumer>
  </IndexLayout>
)

export default ContactSuccessPage
